// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.
@use 'assets/styles/theme-tpn.scss' as theme;

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes(theme.$light-theme);

/* You can add global styles to this file, and also import other style files */

// Tailwind css
@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  height: 100%;
}

body {
  margin: 0;
}

/**
  * Custom styles
 */
:root {
  // Couleurs primaires
  --primary-10: #{mat.get-theme-color(theme.$light-theme, primary, 10)};
  --primary-20: #{mat.get-theme-color(theme.$light-theme, primary, 20)};
  --primary-50: #{mat.get-theme-color(theme.$light-theme, primary, 50)};
  --primary-100: #{mat.get-theme-color(theme.$light-theme, primary, 100)};
  --primary-200: #{mat.get-theme-color(theme.$light-theme, primary, 200)};
  --primary-300: #{mat.get-theme-color(theme.$light-theme, primary, 300)};
  --primary-400: #{mat.get-theme-color(theme.$light-theme, primary, 400)};
  --primary-500: #{mat.get-theme-color(theme.$light-theme, primary, 500)};
  --primary-600: #{mat.get-theme-color(theme.$light-theme, primary, 600)};
  --primary-700: #{mat.get-theme-color(theme.$light-theme, primary, 700)};
  --primary-800: #{mat.get-theme-color(theme.$light-theme, primary, 800)};
  --primary-900: #{mat.get-theme-color(theme.$light-theme, primary, 900)};

  // Couleurs secondaires (Accent)
  --secondary-10: #{mat.get-theme-color(theme.$light-theme, accent, 10)};
  --secondary-20: #{mat.get-theme-color(theme.$light-theme, accent, 20)};
  --secondary-50: #{mat.get-theme-color(theme.$light-theme, accent, 50)};
  --secondary-100: #{mat.get-theme-color(theme.$light-theme, accent, 100)};
  --secondary-200: #{mat.get-theme-color(theme.$light-theme, accent, 200)};
  --secondary-300: #{mat.get-theme-color(theme.$light-theme, accent, 300)};
  --secondary-400: #{mat.get-theme-color(theme.$light-theme, accent, 400)};
  --secondary-500: #{mat.get-theme-color(theme.$light-theme, accent, 500)};
  --secondary-600: #{mat.get-theme-color(theme.$light-theme, accent, 600)};
  --secondary-700: #{mat.get-theme-color(theme.$light-theme, accent, 700)};
  --secondary-800: #{mat.get-theme-color(theme.$light-theme, accent, 800)};
  --secondary-900: #{mat.get-theme-color(theme.$light-theme, accent, 900)};

  // Autres variables
  --mat-toolbar-container-background-color: white;
  --mat-table-header-headline-weight: 700;
  --mat-table-row-item-label-text-weight: 500;
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-fab-small-container-elevation-shadow: none;
  --mdc-protected-button-container-elevation-shadow: none;
  --mdc-protected-button-hover-container-elevation-shadow: none;
  --mdc-fab-small-hover-container-elevation-shadow: none;

  --mdc-filled-button-label-text-tracking: normal;
  --mdc-outlined-button-label-text-tracking: normal;
  --mdc-protected-button-label-text-tracking: normal;

  // Couleur du texte pour les dialogues
  --mdc-dialog-supporting-text-color: black;
  --mdc-dialog-supporting-text-size: 14px;

  --mat-table-row-item-label-text-color: black;

  --primary: #{mat.get-theme-color(theme.$light-theme, primary, 500)};
  --secondary: #{mat.get-theme-color(theme.$light-theme, accent, 500)};
  --warn: #{mat.get-theme-color(theme.$light-theme, warn, 500)};
}

.mat-mdc-tab-header {
  --mat-tab-header-label-text-tracking: normal !important;
}

.mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-outline-color: var(--primary);
}

mat-tab-nav-panel {
  background-color: #{mat.get-theme-color(theme.$light-theme, primary, 50)};
}

mat-paginator {
  @apply rounded-b-xl;
  display: flex;
  @apply justify-center;
}

.mdc-notched-outline__notch {
  border-right: 0;
}

h1 {
  @apply text-primary-500;
  @apply font-semibold;
  @apply text-3xl;
  @apply my-8;
}

h2 {
  @apply text-xl;
  @apply font-semibold;
  @apply text-black;
  @apply mt-8 #{!important};
  @apply mb-6;
}

h3 {
  @apply font-bold;
  @apply whitespace-nowrap;
  @apply text-base;
}

.side-dialog {
  --mdc-dialog-container-shape: 0;
}

.custom-dialog {
  .mat-mdc-dialog-content {
    max-height: unset;
  }
}
